import React, { useContext, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import {auth, generateUserDocument, functions} from "../firebase";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { useHistory } from "react-router-dom";

import Loading from './Loading'
import Billing from './Billing'

const getNumbers = functions.httpsCallable('getNumbers');
const setNumber  = functions.httpsCallable('setNumber');
const createWebhook = functions.httpsCallable('createWebhook');

const Account = (props) => {
    const user = useContext(UserContext).user || {};
    const {email, displayName, smsPhoneNumber, typeformAccessToken} = user;
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState(props.match.params.tab);
    const history = useHistory();

    return (
        <div id="accountPage">

            <Tabs activeKey={props.match.params.tab} transition={false} onSelect={(k) => {
                setTabActiveKey(k); 
                history.push('/account/' + k);
            }}>
                <Tab eventKey="profile" title="Profile">
                    <Form>
                        <Form.Group>
                            <Form.Label htmlFor="userEmail" className="block">
                                Email
                            </Form.Label>
                            <Form.Text muted>
                                This is your account email address. Currently, it cannot be changed.
                            </Form.Text>
                            <Form.Control
                                type="email"
                                name="userEmail"
                                defaultValue={email}
                                id="userEmail"
                                disabled
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="displayName" className="block">
                                Display Name
                            </Form.Label>
                            <Form.Text muted>
                                Your display name will be shown publicly to users.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="displayName"
                                defaultValue = {displayName}
                                id="displayName"
                                onBlur = {(event) => {
                                    event.preventDefault()
                                    const { name, value } = event.currentTarget;
                                    return auth.currentUser.updateProfile({
                                        displayName: value,
                                    }).then((user) => {
                                        return generateUserDocument(auth.currentUser, {displayName: value})
                                    })
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Tab>

                <Tab eventKey="billing" title="Billing">
                    {(tabActiveKey == 'billing' || props.match.params.tab == 'billing') &&
                        <Billing
                            user={user} />
                    }
                </Tab>

                <Tab eventKey="sms" title="Text Messages">
                    <Form>
                        <Form.Group>
                            <Form.Label htmlFor="smsPhoneNumber" className="block">
                                SMS Phone Number
                            </Form.Label>
                            <Form.Text muted>
                                Curbd provides you with a phone number for customers to send text messages.
                            </Form.Text>
                            <Form.Control
                                type="hidden"
                                name="smsPhoneNumber"
                                defaultValue = {smsPhoneNumber}
                                id="smsPhoneNumber"
                            />
                            {loading && 
                                <Loading />
                            }

                            {!loading && error &&
                                <div className="alert alert-danger">{error}</div>
                            }

                            {!loading && phoneNumbers.length == 0 && !smsPhoneNumber && 
                                <div>
                                    <div className="alert alert-info">
                                        Click the button below to search for a phone number in your local area code. Sorry, you cannot use your existing phone number.
                                    </div>
                                    <Button onClick={(event) => {
                                        const areaCode = prompt("What area code would you like to search for?");
                                        if(areaCode) {
                                            setLoading(true)
                                            setError(false)
                                            getNumbers({country: 'US', areaCode: areaCode})
                                                .then((result) => {
                                                    setLoading(false)

                                                    if(result.data.length) {
                                                        return setPhoneNumbers(result.data)
                                                    }

                                                    return setError("Sorry, there are no numbers available in this area code. Please try another area code used in your area.")
                                                    
                                                })
                                        }
                                    }}>
                                        <i className="fas fa-search"></i>{" "}
                                        Choose a Number
                                    </Button>
                                </div>
                            }

                            {!loading && phoneNumbers.length == 0 && smsPhoneNumber &&
                                <p>
                                    <small>Your phone number is currently set to <b>{smsPhoneNumber}</b>.</small>
                                    <br />
                                    <Button onClick={(event) => {
                                        const areaCode = prompt("What area code would you like to search for?");
                                        if(areaCode) {
                                            setLoading(true)
                                            setError(false)
                                            getNumbers({country: 'US', areaCode: areaCode})
                                                .then((result) => {
                                                    setLoading(false)
                                                    
                                                    if(result.data.length) {
                                                        return setPhoneNumbers(result.data)
                                                    }

                                                    return setError("Sorry, there are no numbers available in this area code. Please try another area code used in your area.")
                                                })
                                        }
                                    }}>
                                        <i className="fas fa-search"></i>{" "}
                                        Choose a new number
                                    </Button>
                                </p>
                            }

                            {!loading && phoneNumbers.length > 0 &&
                                <ul className="list-group">
                                    {phoneNumbers.map(el => {
                                        return (
                                            <li className="list-group-item" key={el.phoneNumber}>
                                                <a href="#" onClick={(event) => {
                                                    const { name, value } = event.currentTarget;
                                                    setLoading(true)
                                                    setNumber({phoneNumber: el.phoneNumber, uid: user.uid})
                                                        .then(async () => {
                                                            setLoading(false)
                                                            setPhoneNumbers([])
                                                            setError(false)
                                                            await generateUserDocument(user, {smsPhoneNumber: el.phoneNumber})
                                                            history.push("/messages")
                                                        })
                                                    
                                                }}>
                                                    {el.friendlyName}
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </Form.Group>
                    </Form>
                </Tab>

                <Tab eventKey="typeform" title="TypeForm">
                    <Form>
                        <Form.Group>
                            <Form.Label htmlFor="accessToken" className="block">
                                TypeForm Personal Access Token
                            </Form.Label>
                            <Form.Text muted>
                                To locate your TypeForm personal access token, please click <a href="https://developer.typeform.com/get-started/personal-access-token/" target="_blank">here</a>. Once loated, paste it into the text box below.
                            </Form.Text>
                            <Form.Control
                                type="text"
                                name="typeformAccessToken"
                                defaultValue = {(typeformAccessToken && typeformAccessToken.length ? typeformAccessToken.replace(/.(?=.{4,}$)/g, '*') : '')}
                                id="typeformAccessToken"
                                onBlur = {(event) => {
                                    event.preventDefault()
                                    const { name, value } = event.currentTarget;
                                    if(value.indexOf('*') < 0) {
                                        return auth.currentUser.updateProfile({
                                            typeformAccessToken: value,
                                        }).then((user) => {
                                            return generateUserDocument(auth.currentUser, {typeformAccessToken: value})
                                        })
                                    }
                                }}
                            />
                        </Form.Group>
                        {typeformAccessToken && typeformAccessToken.length > 0 &&
                            <Form.Group>
                                <Form.Label htmlFor="webhook" className="block">
                                    Create TypeForm Webhook
                                </Form.Label>
                                <Form.Text muted>
                                    Click the button below to configure a new webhook with TypeForm.
                                </Form.Text>
                                <div>
                                    <Button onClick={(event) => {
                                        event.preventDefault()
                                        const uid = prompt("Whats the TypeForm form UID (this can be found at admin.typeform.com)?");

                                        if(!uid) {
                                            return
                                        }

                                        const url = "https://us-central1-curbd-io.cloudfunctions.net/receiveWebhook";
                                        const enabled = true;

                                        return createWebhook({
                                            uid: uid,
                                            tag: "curbd-integration-" + uid,
                                            url: url,
                                            enabled: enabled,
                                            access_token: typeformAccessToken,
                                        })
                                        .then((result) => {
                                            console.log(result)
                                            alert("Created!")

                                            return
                                        })
                                    }}>
                                        <i className="fas fa-plus"></i>{" "}
                                        Create a TypeForm Webhook
                                    </Button>
                                </div>
                            </Form.Group>
                        }
                    </Form>
                </Tab>
            </Tabs>
        </div>
    ) 
};

export default Account;
