import {useState} from 'react'
import {storage} from '../firebase';

import Loading from './Loading';

import { LazyLoadImage } from 'react-lazy-load-image-component';

const MessageImage = (props) => {
	const [url, setUrl] = useState(false)

	const fileRef =  storage.refFromURL(props.data.data);

	fileRef.getDownloadURL().then((theUrl) => {
		setUrl(theUrl)
	})
	
	if(!url) {
		return null
	}

	return (
		<div className={"messageLogItem " + (props.data.type.indexOf('outgoing_message') > -1 ? 'me' : '')}>
			<a href={url} target="_blank">
			<LazyLoadImage
		      	alt={"User generated image"}
		      	height={(props.isMobile ? 100 : 200)}
		      	src={url}
		      	width={(props.isMobile ? 250 : null)} 
		      	placeholder={
		      		<div style={{margin: "0 auto", height: props.isMobile ? 100 : 200, width: 250}}>
		      			<span> </span>
		      		</div>		      	
		      	}
		    />
			</a>
		</div>
	)
}

export default MessageImage;