import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";

import { firebaseConfig } from './firebaseConfig';

firebase.initializeApp(firebaseConfig);

const provider = new firebase.auth.GoogleAuthProvider();

// call this to sign in users with Google OAuth
export const signInWithGoogle = () => {
  // firebase gives us this
	return auth.signInWithPopup(provider);
};

// create/update user document
export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  const { email, displayName, photoURL } = user;


  // create a new user
  if (!snapshot.exists) { 
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  } else {
    // update an existing user
  	firestore.collection("users").doc(user.uid).update({
        displayName,
        email,
        photoURL,
        ...additionalData
    });
  }
  
  // give them the user back
  return getUserDocument(user.uid);
};

// get user document
export const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const FieldValue = firebase.firestore.FieldValue;
export const messaging = firebase.messaging