import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import {loadStripe} from '@stripe/stripe-js/pure';

import {auth, firestore, functions} from '../firebase'

import Loading from './Loading'

const goToCustomerPortal = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
const getUsageRecords = functions.httpsCallable('getUsageRecords')

export default class Billing extends React.Component
{
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			plans: [],
			currentSubscription: {},
			currentUsage: {
				count: null,
				allowed: null,
			}
		}

		this.startCheckoutSession = this.startCheckoutSession.bind(this)
		this.goToCustomerPortal = this.goToCustomerPortal.bind(this)
	}

	componentDidMount() {
		this.getPlans()
		this.getCurrentSubscription()
		this.getUsageRecords()
	}

	async getUsageRecords() {
		const { data } = await getUsageRecords({uid: auth.currentUser.uid});

		this.setState({
			currentUsage: data,
		})
	}

	getCurrentSubscription() {
		firestore.collection('users')
		  	.doc(auth.currentUser.uid)
		  	.collection('subscriptions')
		  	.where('status', 'in', ['trialing', 'active'])
		  	.get()
		  	.then((querySnapshot) => {
		  		if(!querySnapshot.empty) {
			    	let doc = querySnapshot.docs[0].data()
			    	doc.id = querySnapshot.docs[0].id

			    	this.setState({
			    		currentSubscription: doc
			    	})
			    }
		  	})
	}

	getPlans() {
		let plans = []

		return firestore.collection('plans')
		.where('active', '==', true)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach(async (plan) => {
				let prices = []
				const planId = plan.id
				const priceSnap = await plan.ref.collection("prices").where('active', '==', true).get()
				priceSnap.docs.forEach((price) => {
					const priceId = price.id
					price = price.data()
					price.id = priceId
					prices.push(price)
				})

				plan = plan.data()
				plan.id = planId
				plan.prices = prices
				plan.lowestPrice = prices[0].unit_amount

				plans.push(plan)

				this.setState({
					plans: plans.sort((a, b) => (a.lowestPrice > b.lowestPrice) ? 1 : -1),
				})
			})
		})
		.then(() => {
			this.setState({
				loading: false,
			})
		})
	}

	async startCheckoutSession(priceId) {
		this.setState({
			loading: true,
		})

		const docRef = await firestore
		  	.collection('users')
		  	.doc(auth.currentUser.uid)
		  	.collection('checkout_sessions')
		  	.add({
		   		price: priceId,
		    	success_url: window.location.origin + "/account/billing",
		    	cancel_url: window.location.origin + "/account/billing",
		  	});

		// Wait for the CheckoutSession to get attached by the extension
		docRef.onSnapshot(async (snap) => {
		  	const { error, sessionId } = snap.data();
		  	if (error) {
		    	// Show an error to your customer and 
		    	// inspect your Cloud Function logs in the Firebase console.
		    	alert(`An error occured: ${error.message}`);
		  	}
		  	if (sessionId) {
		    	// We have a session, let's redirect to Checkout
		    	// Init Stripe
		    	// TODO :: move to config
		    	const stripe = await loadStripe('pk_live_mppnOGYVsinscXgnexbbe4CD006FOe3DX8');
		    	stripe.redirectToCheckout({ sessionId });
		  	}

		  	setTimeout(() => {
		  		this.setState({
			  		loading: false,
			  	})
		  	}, 1000)
		});
	}

	async goToCustomerPortal() {
		this.setState({
			loading: true,
		})

		const { data } = await goToCustomerPortal({ returnUrl: window.location.origin + "/account/billing" });
		window.location.assign(data.url);
	}

	render() {

		if(this.state.loading) {
			return (
				<div style={{margin: "0 auto", width: 200}}>
					<Loading />
				</div>
			)
		}

		return (
			<div id="billingPage">
				{this.state.currentSubscription.id &&
					<Row>
						<Col>
							<div className="alert alert-info">
								You've had <b>{this.state.currentUsage.count}</b> customer visits (of <b>{this.state.currentUsage.allowed}</b> allowed) this billing period!
							</div>
							<div>
								<Button block variant="primary" onClick={this.goToCustomerPortal}>
									Manage Billing Account
								</Button>
							</div>
						</Col>
					</Row>
				}
				{false && !this.state.currentSubscription.id &&
					<Row>
						<Col>
							<h4>Pricing</h4>
							<p>Pricing is based on the number of customer visits in a month.</p>
							<table className="table table-striped table-responsive">
								<thead>
									<tr>
										<th># of Visits</th>
										<th>Price / Visit</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>1-20</td>
										<td>$0.25</td>
									</tr>

									<tr>
										<td>21-100</td>
										<td>$0.20</td>
									</tr>

									<tr>
										<td>101-250</td>
										<td>$0.15</td>
									</tr>

									<tr>
										<td>251 - 500</td>
										<td>$0.10</td>
									</tr>

									<tr>
										<td>501 - ∞</td>
										<td>$0.08</td>
									</tr>

								</tbody>
							</table>

							<Button variant="primary" size="lg" onClick={() => {
					    		this.startCheckoutSession(this.state.plans[0].prices[0].id)	
					    	}}>
					    		Sign Up Now!
					    	</Button>

						</Col>
					</Row>

				}

				{!this.state.currentSubscription.id &&
					<Row>
						<Col>
							<Card>
							  	<Card.Body>
							    	<Card.Title><b>Curbd Free Plan</b></Card.Title>
							    	<Card.Text>
							      		The free plan is perfect for trying out the app and includes 10 visits each month, totally free!
							    	</Card.Text>
							    	<Button variant="primary" block disabled>
							    		Subscribed!
							    	</Button>
							  	</Card.Body>
							</Card>
						</Col>

						{this.state.plans.map((el) => {
							const price = el.prices[0]
							return (
								<Col key={el.id}>
									<Card>
									  	<Card.Body>
									    	<Card.Title><b>{el.name}</b></Card.Title>
									    	<Card.Text>
									      		{el.description}
									    	</Card.Text>
									    	<Button variant="primary" block onClick={() => {
									    		this.startCheckoutSession(price.id)	
									    	}}>
									    		${(price.unit_amount / 100).toFixed(2)} per {price.interval}
									    	</Button>
									  	</Card.Body>
									</Card>
								</Col>
							)
						})}

						<Col>
							<Card>
							  	<Card.Body>
							    	<Card.Title><b>Curbd Enterprise Plan</b></Card.Title>
							    	<Card.Text>
							      		If you need more than 500 interactions per month, please contact us!
							    	</Card.Text>
							    	<Button variant="primary" block href="mailto:sales@curbd.app">
							    		Contact Us
							    	</Button>
							  	</Card.Body>
							</Card>
						</Col>
					</Row>
				}
			</div>
		)
	}
}