import React from 'react';

import InteractionStatus from './InteractionStatus';

import * as timeago from 'timeago.js';

import InfiniteScroll from 'react-infinite-scroll-component';

import Loading from './Loading';

const locale = (number, index, totalSec) => {
  // number: the time ago / time in number;
  // index: the index of array below;
  // totalSec: total seconds between date to be formatted and today's date;
  return [
    ['just now', 'right now'],
    ['%s secs ago', 'in %s secs'],
    ['1 min ago', 'in 1 min'],
    ['%s min ago', 'in %s min'],
    ['1 hr ago', 'in 1 hr'],
    ['%s hrs ago', 'in %s hrs'],
    ['1 day ago', 'in 1 day'],
    ['%s days ago', 'in %s days'],
    ['1 week ago', 'in 1 week'],
    ['%s weeks ago', 'in %s weeks'],
    ['1 month ago', 'in 1 month'],
    ['%s months ago', 'in %s months'],
    ['1 year ago', 'in 1 year'],
    ['%s years ago', 'in %s years']
  ][index];
};

timeago.register('en_US', locale);

const MessagesSidebar = (props) => {
	return (
		<div id="messagesSidebar">
			<a className="btn btn-primary btn-block" href="#" onClick={props.toggleComposeModal} style={{marginBottom: 10}}>
				<i className="fas fa-pencil-alt"></i>
				{" "}
				New
			</a>
			<InfiniteScroll
			  	dataLength={props.interactions.length}
			  	next={props.getInteractions}
			  	hasMore={props.hasMore}
			  	loader={
			  		<div style={{width: 100, margin: "0 auto"}}>
			  			<Loading />
			  		</div>
			  	}
			  	endMessage={
			    	<p style={{ textAlign: 'center' }}>
			      		<b>
			      			<i className="fas fa-user-ninja"></i>
			      			Yay! You have seen it all
			      		</b>
			    	</p>
			  	}
			  	scrollableTarget={props.isMobile ? null : "messagesSidebar"}
			>
				{props.interactions.map((el) => {
					return (
						<div className={"messagesSidebarItem " + (props.currentInteraction && props.currentInteraction.id && props.currentInteraction.id == el.id ? "selected" : "")} key={el.id} onClick={() => {
								props.selectInteraction(el)
							}}>
								
								<span className="float-right" style={{textAlign: "right"}}>
									<InteractionStatus data={el} />
									<br />
									<span className="text-muted">
										{timeago.format(el.updated.seconds * 1000)}
									</span>
								</span>
							
								<b>{props.formatNumber(el.customer_number)}</b>
								
								<br />

								<small className="messagePreview">
									{(el.lastMessage ? el.lastMessage.substr(0, props.isMobile ? 60 : 74) : <span>&nbsp;</span>)}
								</small>

								

							
						</div>
					)
				})}
			</InfiniteScroll>
		</div>
	)
}

export default MessagesSidebar;