import React, { useContext } from 'react';
import {Redirect, Route} from 'react-router-dom';
import {auth} from '../firebase';

import { UserContext } from "../providers/UserProvider";
import Loading from './Loading';

export const PrivateRoute = ({component: Component, ...rest}) => {
	const user = useContext(UserContext) || {};

	return (
		<Route {...rest} render={(props) => 
			auth.currentUser ? (
				<Component {...props} />
			) : user.loading ?
				<div style={{margin: "0 auto", width: 200}}>
					<Loading />
				</div>
			: (
				<Redirect to="/" />
			)
		} />
	);
}