import React, { Component, createContext } from "react";
import { auth, firestore, getUserDocument, messaging, FieldValue } from "../firebase";

// alerts for push notifications
import { alert, defaultModules } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import * as PNotifyBootstrap4 from '@pnotify/bootstrap4';

import Pusher from 'pusher-js';

defaultModules.set(PNotifyBootstrap4, {});

// sound for push notifications
const sound = new Audio('/woof.mp3');

// this function is a safari hack to get permission to play the sound const later on when a push notification arrives.
// we just want to do this up front
const unlockAudio = (continuePlay) => {
    // try to play the sound
    try {
        sound.play()
        .then(() => {
            // but, if continuePlay is not false, we don't really want to play it right now
            // we want to hack around safari's audio restrictions
            // so, we play it and immediately stop
            if(continuePlay !== true) {
                sound.pause();
                sound.currentTime = 0;
            }
        })
        .then(() => { 
            // now, remove the event listener because we hacked safari
            document.body.removeEventListener('click', unlockAudio)
            document.body.removeEventListener('touchstart', unlockAudio)
        })
        .catch((err) => {
            // just in case anything went wrong, let's still get rid of those listeners
            // good riddance!
            document.body.removeEventListener('click', unlockAudio)
            document.body.removeEventListener('touchstart', unlockAudio)
        })
    } catch {
        // triple redundancy = good?
        document.body.removeEventListener('click', unlockAudio)
        document.body.removeEventListener('touchstart', unlockAudio)
    }
}


export const UserContext = createContext({ user: null, loading: true });
class UserProvider extends Component {
    state = {
        user: null, // currently logged in user,
        loading: true,
    };

    // send a push notification to users!
    notifyMe(title, options) {
        // this device doesn't support web push notification API, so use alert()
        if (!window.Notification) {
            const myAlert = alert({
                title: title,
                text: options.body,
            });

            myAlert.on('click', (e) => {
                myAlert.close()
            })
        } else {
            // check if permission is already granted for notifications
            if (Notification.permission === 'granted') {
                // show notification here
                var notify = new Notification(title, {
                    body: options.body,
                    icon: options.icon,
                });
            } else {
                // request permission from user to show notification
                Notification.requestPermission().then(function (p) {
                    if (p === 'granted') {
                        // show notification here
                        var notify = new Notification(title, {
                            body: options.body,
                            icon: options.icon,
                        });
                    } else {
                        console.log('User blocked notifications.');
                    }
                }).catch(function (err) {
                    console.error(err);
                });
            }
        }

        // play the sound! woof!
        try {
            unlockAudio(true)
        } catch {
            console.log('erer!')
        }
    }

    componentDidMount = () => {
        // this is fired when the user logs in/out
        auth.onAuthStateChanged(async userAuth => {
            if(!userAuth) {
                this.setState({user: null, loading: false})
                return
            }

            const user = await getUserDocument(userAuth.uid);
            
            // if the user is logged in...
            if(user) {

                // this makes the user obj available everywhere
                this.setState({ user: user, loading: false });

                // event listener to update the user object when the document is updated
                firestore.collection("users").doc(user.uid).onSnapshot(async (doc) => {
                    const u = await getUserDocument(user.uid);
                    this.setState({user: u, loading: false });
                });

                // let's deal with push notifications...
                try {
                    // grab a firebase messaging instance
                    const messagingInstance = messaging()

                    // TODO:: move key to config file
                    // here, we're going to subscribe to push notifications with the web push notification api
                    messagingInstance
                    .getToken({vapidKey: "BEPqLUoSTRpvseFuvOBcWF80cYdllwKehHd09WBbzMI4h47ZoWntS_jTkIigKXyzYzSu0agdXKeHbNuhUmoLymQ"})
                    .then((currentToken) => {
                        // if we got a token, let's register it in the database!
                        if (currentToken) {
                            // first, check to see if the token exists already
                            return firestore.collection("tokens")
                                .where('uid', '==', auth.currentUser.uid)
                                .get()
                                .then((querySnapshot) => {
                                    let exists = false;

                                    querySnapshot.forEach((doc) => {
                                        let datum = doc.data()

                                        if(datum.token == currentToken) {
                                            exists = true
                                        }
                                    })

                                    return exists
                                })
                                .then((exists) => {
                                    // if the token doesn't exist yet, add it to the database
                                    if(!exists) {
                                        return firestore.collection("tokens").doc().set({
                                            uid: auth.currentUser.uid,
                                            token: currentToken,
                                            created: FieldValue.serverTimestamp(),
                                        })
                                    }

                                    // if the token does already exist, then we can move on.
                                    return
                                })
                            }
                    })
                    // next we want to register the service worker for background notifications
                    .then(() => {
                        return messagingInstance
                            .requestPermission()
                            .then(() => {
                                // if service workers are available in our browser, let's try to register it.
                                if ('serviceWorker' in navigator) {
                                    return navigator.serviceWorker.register('./firebase-messaging-sw.js')
                                }
                            })
                            .catch((err) => {
                                console.log('error', err)
                            })
                    })
                    .catch((err) => {
                      console.log('An error occurred while retrieving token. ', err);
                    })

                    // this is an event handler for foreground notifications
                    messagingInstance.onMessage((payload) => {                        
                        this.notifyMe(payload.notification.title, {body: payload.notification.body, icon: '/logo192.png'})
                    })

                } catch {
                    // if we're in safari (desktop & mobile), which doesn't do web push notifications, we want to polyfill with pusher!
                    if(window.safari !== undefined || navigator.userAgent.match(/(iPod|iPhone|iPad)/) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0)) {

                        // Enable pusher logging - don't include this in production
                        Pusher.logToConsole = true;

                        // TODO :: move to config
                        var pusher = new Pusher('9820139c6b959c061f53', {
                          cluster: 'us2'
                        });

                        // subscribe to pusher channel, just the user's ID
                        var channel = pusher.subscribe(auth.currentUser.uid);

                        // this is an event handler for pusher's incoming notifications...
                        channel.bind('notification', (data) => {
                           this.notifyMe(data.title, {body: data.body, icon: '/logo192.png'})
                        });
                    }
                }
            } else {
                // log the user out
                this.setState({user: null, loading: false})
            }
        });
    
        // this is a safari hack. we want to allow our app to play the woof with incoming notifications
        // but, with safari we need the user to click on something, lol. so, let's listen to their first click, then unsubscribe later    
        document.body.addEventListener('click', unlockAudio);
        document.body.addEventListener('touchstart', unlockAudio);
    };

    render() {
        return (
            <UserContext.Provider value={{user: this.state.user, loading: this.state.loading}}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider;