import React, {useState} from "react";

import { Link, useHistory } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { signInWithGoogle, auth } from '../firebase'

const LogIn = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const signInWithEmailAndPasswordHandler = (event, email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password)
        .then(() => {
            history.push("/");
        })
        .catch(error => {
            setError(error.message || "Error signing in with password and email!");
        });
    };
    const signInWithGoogleThenNav = () => {
        signInWithGoogle()
        .then(() => {
            history.push("/")
        })
        .catch(error => {
            setError(error.message || "Error signing in with Google!");
        })
    }

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userEmail') {
            setEmail(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

    return (
        <div>
            <h1>Sign In</h1>
            <div>
                {error !== null && 
                    <div className="alert alert-danger">{error}</div>
                }
            </div>
            <Form>
                <Form.Group>
                    <Form.Label htmlFor="userEmail" className="block">
                        Email
                    </Form.Label>
                    <Form.Control
                        type="email"
                        name="userEmail"
                        value = {email}
                        id="userEmail"
                        onChange = {(event) => onChangeHandler(event)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="userPassword" className="block">
                        Password
                    </Form.Label>
                    <Form.Control
                        type="password"
                        name="userPassword"
                        value = {password}
                        id="userPassword"
                        onChange = {(event) => onChangeHandler(event)}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
                    Sign in
                </Button>
            </Form>
            <p>or</p>
            <Button variant="secondary" onClick={() => {signInWithGoogleThenNav()}}>
                    Sign in with Google
            </Button>
            <p className="text-center">
                Don't have an account?{" "}
                <Link to="/signup">
                    Sign up here
                </Link>
                <br />
                <Link to="/forgot">
                    Forgot Password?
                </Link>
            </p>
        </div>
    );
};

export default LogIn;