import Badge from 'react-bootstrap/Badge';

const InteractionStatus = (props) => {

	let variant;
	let status;

	switch(props.data.status) {
		case 'new':
			variant = 'primary';
			break;

		case 'in_progress':
			variant = 'secondary';
			break;

		case 'complete':
			variant = 'success';
			break;

		default: 
			variant = '';
			break;
	}

	
	switch(props.data.status) {
		case 'new':
			status = 'New';
			break;

		case 'in_progress':
			status = 'In Progress';
			break;

		case 'complete':
			status = 'Complete';
			break;

		default: 
			status = '';
			break;
	}

	return (
		<Badge pill variant={variant}>
			{status}
		</Badge>
	)
}

export default InteractionStatus;