import React, { useContext } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Link, useHistory } from 'react-router-dom';

import { auth } from "../firebase";

import { UserContext } from "../providers/UserProvider";

function TopNav() {
	const history = useHistory();
	const user = useContext(UserContext).user || null;
	return (
		<Navbar collapseOnSelect={true} expand="lg" sticky="top">
		  	<Navbar.Brand>
		  		<Link to="/">
		  			<img src="/curbd-logo.png" alt="Curbd Title" />
		  		</Link>
		  	</Navbar.Brand>
		  	<Navbar.Toggle aria-controls="responsive-navbar-nav" />
		  	<Navbar.Collapse id="responsive-navbar-nav">
			  	<Nav className="mr-auto">
			  		<Nav.Link>
			  			<Link to="/messages"><i className="fas fa-comment"></i> Messages</Link>
			  		</Nav.Link>
			  		<Nav.Link>
			  			<Link to="/macros"><i className="fas fa-copy"></i> Templates</Link>
			  		</Nav.Link>
			  	</Nav>
			  	<Nav className="justify-content-end">
		  			<NavDropdown title={
		  					<span> 
		  						<i className="fas fa-user-ninja"></i> 
		  						{" "}
		  						{(user ? user.displayName || user.email : "Not Signed In")}
		  					</span>
		  				}>
						{user !== null &&
							<NavDropdown.Item>
								<Link to="/account/profile"><i className="fas fa-cogs"></i> My Account</Link>
							</NavDropdown.Item>
						}

						{user !== null &&
					        <NavDropdown.Item onClick={() => {
					        	auth.signOut(); 
					        	history.push('/');
					        }}>
					        	<i className="fas fa-sign-out-alt"></i>{" "}
					        	Logout
					        </NavDropdown.Item>
				    	}
				    
				    	{user == null &&
				  			<NavDropdown.Item>
				  				<Link to="/login"><i className="fas fa-sign-in-alt"></i> Log In</Link>
				  			</NavDropdown.Item>
				  		}

				  		{user == null &&
				  			<NavDropdown.Item>
				  				<Link to="/signup"><i className="fas fa-user-plus"></i> Sign Up</Link>
				  			</NavDropdown.Item>
				  		}
				    </NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default TopNav;