import { BrowserRouter, Route, Switch } from 'react-router-dom';

import UserProvider from "./providers/UserProvider";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// our custom route component to ensure only logged in users access this route
import { PrivateRoute } from './components/PrivateRoute';

// our app components
import LogIn from './components/LogIn';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import Account from './components/Account';

import TopNav from './components/TopNav';

import Home from './components/Home';
import Messages from './components/Messages';
import Macros from './components/Macros';

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <TopNav />
        <Container fluid>
          <Row>
            <Col> 
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={LogIn} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/forgot" component={ForgotPassword} />
                <PrivateRoute path="/account/:tab?" component={Account} />
                <PrivateRoute path="/messages/:interaction?" component={Messages} />
                <PrivateRoute exact path="/macros" component={Macros} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
