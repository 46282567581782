import React from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Loading from './Loading';

import { auth, firestore, getUserDocument } from '../firebase';

export default class Macros extends React.Component
{
	constructor(props) {
		super(props)

		this.state = {
			create: false,
			editObj: {},
			macros: [],
			loading: true,
			user: {},
		}

		this.createMacro = this.createMacro.bind(this)
	}

	async componentDidMount() {
		this.getMacros()

		this.getUserDocument()
	}

	async getUserDocument() {
		this.setState({
			user: await getUserDocument(auth.currentUser.uid)
		})
	}

	getMacros() {
		firestore.collection("macros").where("uid", "==", auth.currentUser.uid).get().then((querySnapshot) => {
			let data = []

			querySnapshot.forEach((doc) => {
	            // doc.data() is never undefined for query doc snapshots
	            let datum = doc.data();
	            datum.id = doc.id;

	            data.push(datum)
	        });

        	this.setState({
        		macros: data,
        		loading: false,
        	})
		});
	}

	createMacro(e) {
		e.preventDefault();

		this.setState({
			loading: true,
		})

		if(this.state.editObj.system) {
			let obj = {}
			obj[this.state.editObj.systemId] = document.getElementById("macroText").value;

			firestore.collection("users").doc(auth.currentUser.uid).update(obj)
			.then((doc) => {
				this.setState({
					create: false,
					editObj: {},
				})
			})
			.then(() => {
				this.getMacros()
				this.getUserDocument()
			})
		} else if(this.state.editObj.id) {
			firestore.collection("macros").doc(this.state.editObj.id).update({
				name: document.getElementById("macroName").value,
				text: document.getElementById("macroText").value,
			})
			.then((doc) => {
				this.setState({
					create: false,
					editObj: {},
				})
			})
			.then(() => {
				this.getMacros()
			})
		} else {
			firestore.collection("macros").add({
				name: document.getElementById("macroName").value,
				text: document.getElementById("macroText").value,
				uid: auth.currentUser.uid,
			})
			.then((doc) => {
				this.setState({
					create: false,
					editObj: {},
				})
			})
			.then(() => {
				this.getMacros()
			})
		}
		
	}

	editMacro(obj) {
		this.setState({
			create: true,
			editObj: obj,
		})
	}

	deleteMacro(id) {
		const conf = window.confirm('Are you sure you want to delete this macro?')

		if(conf) {
			this.setState({
				loading: true,
			})

			firestore.collection("macros").doc(id).delete().then(() => {
				this.getMacros()
			})
		}
	}

	render() {
		return (
			<div id="macrosPage">

				{this.state.loading && 
					<div style={{margin: "0 auto", width: 100}}>
						<Loading />
					</div>
				}

				{!this.state.create && !this.state.loading &&
					<div>
						
						{this.state.macros.length == 0 &&
							<div className="alert alert-info">
								Templates are used to send canned responses to your customers and are designed to save you time.
								{" "}
								<a onClick={() => { this.setState({create: true}) }} href="#">Click here</a> to create one.
							</div>
						}

						<div>
							<Button style={{marginBottom: 10}} variant="primary" onClick={() => { this.setState({create: true}) }}>
								<i className="fas fa-plus"></i>{" "}
								Create a Template
							</Button>
							<table style={{minWidth: "100%"}} className="table table-striped table-condensed table-responsive">
								<thead>
									<tr>
										<th style={{minWidth: 90}}>Actions</th>
										<th>Name</th>
										<th>Text</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<a href="#" onClick={this.editMacro.bind(this, {name: "New Customer Visit Auto Reply", text: this.state.user.defaultNewInteractionText, system: true, systemId: "defaultNewInteractionText"})}>
												<i className="fas fa-edit fa-lg"></i>
											</a>{" | "}
											<a disabled className="text-muted">
												<i className="fas fa-trash fa-lg"></i>
											</a>
										</td>
										<td>
											New Customer Visit Auto Reply{" "}
											<OverlayTrigger
										      	placement={"bottom"}
										      	trigger={['click', 'hover', 'focus']}
										      	overlay={
										        	<Tooltip>
										          		When a customer first messages you, they'll be sent this template automatically.
										        	</Tooltip>
										      	}
										    >
										      	<i className="fa fa-question-circle"></i>
										    </OverlayTrigger>
										</td>
										<td>
											{this.state.user.defaultNewInteractionText}
										</td>
									</tr>
									{
										this.state.macros.map((el) => {
											return (
												<tr key={el.id}>
													<td>
														<a href="#" onClick={this.editMacro.bind(this, el)}>
															<i className="fas fa-edit fa-lg"></i>
														</a>{" | "}
														<a href="#" onClick={this.deleteMacro.bind(this, el.id)}>
															<i className="fas fa-trash fa-lg"></i>
														</a>
													</td>
													<td>{el.name}</td>
													<td>{el.text}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
				}

				{this.state.create && !this.state.loading &&
					<Form>
		                <Form.Group>
		                    <Form.Label htmlFor="macroName" className="block">
		                        Name
		                    </Form.Label>
		                    {!this.state.editObj.system &&
		                    	<Form.Text muted>
								    The template name will only be shown internally when selecting a template to send a customer.
								</Form.Text>
		                    }
		                    
		                    <Form.Control
		                        type="text"
		                        name="macroName"
		                        id="macroName"
		                        disabled={this.state.editObj.system}
		                        defaultValue={this.state.editObj.name || ""}
		                    />
		                </Form.Group>
		                <Form.Group>
		                    <Form.Label htmlFor="macroText" className="block">
		                        Text
		                    </Form.Label>
		                     <Form.Text muted>
							    The template text will be sent to your customer.
							</Form.Text>
		                    <Form.Control
		                        as="textarea" 
		                        rows={4}
		                        name="macroText"
		                        id="macroText"
		                        defaultValue={this.state.editObj.text || ""}
		                    />
		                </Form.Group>
		                <Button variant="primary" type="submit" onClick={this.createMacro}>
		                	<i className="fas fa-check"></i>{" "}
		                    Save Template
		                </Button>
		                {" "}
		                <Button variant="outline-primary" type="reset" onClick={(event) => { this.setState({create: false, editObj: {}}) }}>
		                    <i className="fas fa-times"></i>{" "}
		                    Cancel
		                </Button>
		            </Form>

				}
			</div>
		)
	}
}