import React, {useState} from "react";

import { useHistory } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { signInWithGoogle, generateUserDocument, auth } from '../firebase'

const SignUp = () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState(null);
    const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
        event.preventDefault();
        try{
          const {user} = await auth.createUserWithEmailAndPassword(email, password);
          generateUserDocument(user, {displayName: displayName});
        }
        catch(error){
          setError('Error Signing up with email and password');
        }

        setEmail("");
        setPassword("");
        setDisplayName("");
        history.push("/")

    };
    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;
        if (name === "userEmail") {
            setEmail(value);
        } else if (name === "userPassword") {
            setPassword(value);
        } else if (name === "displayName") {
            setDisplayName(value);
        }
    };
    const signInWithGoogleThenNav = () => {
        signInWithGoogle()
        .then(() => {
            history.push("/")
        })
        .catch(error => {
            setError(error.message || "Error signing up with Google!");
        })
    };
    return (
        <div>
            <h1>Sign Up</h1>
            <div>
                {error !== null && 
                    <div className="alert alert-danger">{error}</div>
                }
            </div>
            <Form>
                <Form.Group>
                    <Form.Label htmlFor="displayName" className="block">
                        Display Name
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="displayName"
                        value = {displayName}
                        id="displayName"
                        onChange = {(event) => onChangeHandler(event)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="userEmail" className="block">
                        Email
                    </Form.Label>
                    <Form.Control
                        type="email"
                        name="userEmail"
                        value = {email}
                        id="userEmail"
                        onChange = {(event) => onChangeHandler(event)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="userPassword" className="block">
                        Password
                    </Form.Label>
                    <Form.Control
                        type="password"
                        name="userPassword"
                        value = {password}
                        id="userPassword"
                        onChange = {(event) => onChangeHandler(event)}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" onClick = {(event) => {createUserWithEmailAndPasswordHandler(event, email, password)}}>
                    Sign Up
                </Button>
                <p>or</p>
                <Button variant="secondary" onClick={() => {signInWithGoogleThenNav()}}>
                    Sign Up with Google
                </Button>
            </Form>
        </div>
    )
};
export default SignUp;