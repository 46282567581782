import {useState} from 'react'

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {functions, firestore, FieldValue, auth, getUserDocument} from '../firebase';

import {Link} from 'react-router-dom';

const sendMessage = functions.httpsCallable('sendMessage');

const ComposeModal = (props) => {

	const [loading, setLoading] = useState(false)
	const [sendCustomReply, setSendCustomReply] = useState(false)

	const composeMessage = async () => {
		const toNumber = document.getElementById('composeCustomerNumber').value
		const message = document.getElementById('composeMessage').value
		const user = await getUserDocument(auth.currentUser.uid)
		const date = new Date().toISOString().split('T')[0]

		if(!toNumber) {
			alert('Phone number is required!')
			return
		}

		if(!message) {
			alert('A message is required!')
			return
		}

		setLoading(true)

		const interaction = await firestore.collection("interactions").add({
			customer_number: toNumber,
			status: "new",
			uid: user.uid,
			created: FieldValue.serverTimestamp(),
			updated: FieldValue.serverTimestamp(),
			lastMessage: message,
		})

		const usageRecordExists = await firestore.collection("users")
			.doc(user.uid)
			.collection("usage_records")
			.where('date', '==', date)
			.get();

		if(usageRecordExists.empty) {
			const usageRecord = await firestore.collection("users").doc(user.uid).collection("usage_records")
				.doc(date)
				.set({date: date, count: FieldValue.increment(1)});
		} else {
			const usageRecord = await firestore.collection("users").doc(user.uid).collection("usage_records")
				.doc(date)
				.update({date: date, count: FieldValue.increment(1)});
		}

		sendMessage({
			to: toNumber,
			from: user.smsPhoneNumber,
			body: message,
			interactionId:interaction.id,
			uid: user.uid,
		})
		.then(() => {
			setLoading(false)
			props.toggle()
			props.selectInteraction(interaction)
			props.getUsageRecords()
		})
		.catch((err) => {
			setLoading(false)

			alert("There was an error!")
		})
	}
	
	return (
		<Modal show={props.show} onHide={props.toggle} animation={false} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>New Conversation</Modal.Title>
			</Modal.Header>
			<Modal.Body>

				{props.usageRecords && props.usageRecords.allowed <= 10 &&
					<div className="alert alert-danger">
						<i className="fas fa-radiation-alt"></i>
						{" "}Sorry, only paying customers can initiate conversations with customers. To upgrade your plan, <Link to="/account/billing">click here</Link>.
					</div>
				}

				{props.limitReached &&
					<div className="alert alert-danger">
						<i className="fas fa-radiation-alt"></i>
						{" "}Sorry, you have reached your limit on visits this month. To change your plan, <Link to="/account/billing">click here</Link>.
					</div>
				}

				{!props.limitReached && props.usageRecords.allowed > 10 &&
					<Form>
	                    <Form.Group>
	                        <Form.Label htmlFor="composeCustomerNumber" className="block">
	                            Phone Number
	                        </Form.Label>
	                        <Form.Text muted>
	                            Enter the phone number of the customer you want to contact.
	                        </Form.Text>
	                        <Form.Control
	                        	disabled={loading}
	                            type="text"
	                            name="composeCustomerNumber"
	                            id="composeCustomerNumber"
	                        />
	                    </Form.Group>
	                    <Form.Group>
	                        <Form.Label htmlFor="composeMessage" className="block">
	                            Message
	                        </Form.Label>
	                        <Form.Text muted>
	                            Enter the message you would like to say to the customer.
	                        </Form.Text>
	                       	<div style={{display: sendCustomReply ? "" : "none"}}>
	                      		<Form.Control
			                        disabled={loading}
		                            as="textarea"
		                            rows={3}
		                            name="composeMessage"
		                            id="composeMessage"
		                        />
		                    </div>
	                    </Form.Group>
	                    <Form.Group>
							<Dropdown as={ButtonGroup}>
								<Button disabled={loading} variant="secondary" size="sm" style={{}} onClick={() => {
									document.getElementById('composeMessage').value = ''
									setSendCustomReply(!sendCustomReply)
								}}>
									<i className="fas fa-pencil-alt"></i>
									{" "}
									Custom
								</Button>
								<Dropdown.Toggle disabled={loading} split variant="secondary" size="sm" id="dropdown-split-basic" />
								<Dropdown.Menu>
								    <Dropdown.Item href="#" onClick={(e) => {
								    	setLoading(true); 
								    	props.uploadFile.click(e);
								    	setLoading(false)
								    }}>
								    	<i className="fas fa-camera"></i>
								    	{" "}
								    	Send Image
								    </Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
	                    	{props.macros.map((el) => {
								return (
									<Button key={el.id} disabled={loading} variant="primary" size="sm" style={{margin: 5}} onClick={() => {
										document.getElementById('composeMessage').value = el.text
										setSendCustomReply(true)
									}}>
										{el.name}
									</Button>
								)
							})}
	                    </Form.Group>
	                </Form>
	            }
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={loading} variant="secondary" onClick={props.toggle}>
					<i className="fas fa-times"></i>
					{" "}
					Cancel
				</Button>
				<Button disabled={loading} variant="primary" onClick={composeMessage}>
					<i className="fas fa-check"></i>
					{" "}
					Start Conversation
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ComposeModal;