import React, { useState } from "react";
import { auth } from "../firebase";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);

    const onChangeHandler = event => {
        const { name, value } = event.currentTarget;

        if (name === "userEmail") {
            setEmail(value);
        }
    };

    const sendResetEmail = event => {
        event.preventDefault();
        auth
            .sendPasswordResetEmail(email)
            .then(() => {
                setEmailHasBeenSent(true);
                setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
            })
            .catch((error) => {
                setError(error.message || "Error resetting password");
            });
    };

    return (
        <div>
            <h1>Forgot Password</h1>
            <div>
                {emailHasBeenSent && (
                    <div className="alert alert-success">
                      An email has been sent to you!
                    </div>
                )}
                {error !== null && (
                    <div className="alert alert-danger">
                      {error}
                    </div>
                  )}
            </div>
            <Form>
                <Form.Group>
                    <Form.Label htmlFor="userEmail" className="block">
                        Email
                    </Form.Label>
                    <Form.Control
                        type="email"
                        name="userEmail"
                        value = {email}
                        id="userEmail"
                        onChange = {(event) => onChangeHandler(event)}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={event => { sendResetEmail(event) }}>
                    Send Me A Reset Link
                </Button>
            </Form>
        </div>
    );
};

export default ForgotPassword;