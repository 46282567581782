import React from 'react';

import { auth, firestore, functions, storage, getUserDocument, FieldValue } from '../firebase';

import Loading from './Loading';
import InteractionStatus from './InteractionStatus';
import MessagesSidebar from './MessagesSidebar';
import ComposeModal from './ComposeModal';
import MessageImage from './MessageImage';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { Link } from 'react-router-dom';

const sendMessage = functions.httpsCallable('sendMessage');
const getUsageRecords = functions.httpsCallable('getUsageRecords')

export default class Messages extends React.Component
{
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			interactions: [],
			currentInteraction: null,
			interactionEvents: [],
			macros: [],
			isMobile: window.innerWidth < 1024,
			user: {},
			limitReached: false,
			lastVisible: null,
			moreInteractions: true,
			sendCustomReply: false,
			usageRecords: {},
			showComposeModal: false,

		}

		this.currentInteractionListener = null;
		this.interactionsListener = null;
		this.usageInterval = null;

		this.selectInteraction = this.selectInteraction.bind(this)
		this.sendMessage = this.sendMessage.bind(this)
		this.getUsageRecords = this.getUsageRecords.bind(this)
		this.getInteractions = this.getInteractions.bind(this)
		this.toggleComposeModal = this.toggleComposeModal.bind(this)
		this.onChangeFile = this.onChangeFile.bind(this)
	}

	async componentDidMount() {
		// this.getInteractions(this.props.match.params.interaction)
		this.getMacros()
		this.getUsageRecords()

		this.setState({
			user: await getUserDocument(auth.currentUser.uid)
		})

		this.interactionsListener = firestore
			.collection("interactions")
			.where("uid", "==", auth.currentUser.uid)
			.orderBy("created", "desc")
			.limit(10)
		    .onSnapshot((querySnapshot) => {
		        var source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";

		        if(source == "Server") {
		        	this.setInteractions(this.props.match.params.interaction, querySnapshot)
		        }
		    })

		window.onresize = () => {
			this.setState({
				isMobile: window.innerWidth < 1024
			})
		};

		this.usageInterval = setInterval(this.getUsageRecords, 1000 * 60)
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.match.params.interaction != this.props.match.params.interaction) {
			this.doSelectInteraction(this.props.match.params.interaction)
		}
	}

	componentWillUnmount() {
		if(this.interactionsListener) {
			this.interactionsListener()
		}

		if(this.currentInteractionListener) {
			this.currentInteractionListener()
		}

		if(this.usageInterval) {
			window.clearInterval(this.usageInterval);
		}

		window.onresize = () => {

		}
	}

	async getUsageRecords() {
		const { data } = await getUsageRecords({uid: auth.currentUser.uid});
		
		let newState = {
			usageRecords: data,
		}

		if(data.allowed == null) {
			return
		}

		if(data.count > data.allowed) {
			newState.limitReached = true
		}

		this.setState(newState)
	}

	formatNumber(number) {
		let num = number;
		num = num.replace("+1", "")

		return "(" + num[0] + num[1] + num[2] + ") " + num[3] + num[4] + num[5] + "-" + num[6] + num[7] + num[8] + num[9]
	}

	getInteractions(interactionId) {
		if(!this.state.lastVisible) {
			return
		}

		firestore
			.collection("interactions")
			.where("uid", "==", auth.currentUser.uid)
			.orderBy("created", "desc")
			.startAfter(this.state.lastVisible)
			.limit(10)
			.get()
			.then((querySnapshot) => {
				this.setInteractions(interactionId, querySnapshot)
			})
	}

	setInteractions(interactionId, querySnapshot) {
		let data = [ ...this.state.interactions ];
		let newCurrentInteraction = null;

		const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];

		querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let datum = doc.data();
            datum.id = doc.id;

            const foundIndex = data.findIndex(x => x.id == datum.id);
            if(foundIndex >= 0) {
            	data[foundIndex] = datum;
            } else {
            	if(data && data.length && data[0].created.seconds < datum.created.seconds) {
            		data.unshift(datum)
            	} else {
            		data.push(datum)
            	}
            }
        });

        data.forEach((datum) => {
        	if(this.state.currentInteraction && datum.id == this.state.currentInteraction.id) {
            	newCurrentInteraction = datum;
            }
        })

        this.setState({
        	interactions: data,
        	currentInteraction: newCurrentInteraction,
        	loading: false,
        	lastVisible: lastVisible,
        	moreInteractions: (!querySnapshot.docs.length ? false : true),
        }, () => {
        	if(interactionId) {
        		this.doSelectInteraction(interactionId)
        	}

        	if(!this.state.isMobile && this.state.interactions.length > 0 && this.state.interactions.length < 20) {
        		this.getInteractions()
        	}
        })
	}

	selectInteraction(obj) {
		if(obj && obj.id){
			this.props.history.push('/messages/' + obj.id);
		} else {
			this.props.history.push('/messages')
		}
	}

	doSelectInteraction(interactionId) {
		let interaction = this.state.interactions.find(el => el.id == interactionId)

		if(!interaction) {
			interaction = null
		}

		this.setState({
			currentInteraction: interaction,
			interactionEvents: [],
			loading: (interaction && interaction.id ? true : false),
		})

		if(this.currentInteractionListener) {
			this.currentInteractionListener();
		}

		if(interaction && interaction.id) {
			this.currentInteractionListener = firestore
				.collection("interactions")
				.doc(interactionId)
				.collection("events")
				.orderBy("created", "asc")
				.onSnapshot((querySnapshot) => {
					var source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";

        			if(source == "Server") {
			        	this.getInteractionEvents(querySnapshot)
			        }
			    });
		}
	}

	getInteractionEvents(querySnapshot) {
		if(this.state.currentInteraction == null) {
			return
		}

		
		let data = [ ...this.state.interactionEvents ];
		querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let datum = doc.data();
            datum.id = doc.id;

            const foundIndex = data.findIndex(x => x.id == datum.id);
            if(foundIndex >= 0) {
            	data[foundIndex] = datum;
            } else {
            	data.push(datum)
            }
        });

        this.setState({
        	interactionEvents: data,
        	loading: false,
        }, () => {
        	setTimeout(() => {
        		document.getElementById('messagesLog').scrollTop = document.getElementById('messagesLog').scrollHeight;
        	}, 500)
        })
        
	}

	async sendMessage(text, image) {

		if(this.state.limitReached) {
			alert("Sorry, you have reached your limit on visits this month. To change your plan, visit the My Account page!")
			return
		}

		const messageText = document.getElementById('sendMessageText').value || text;
		const user = await getUserDocument(auth.currentUser.uid)

		if((messageText.length || image) && (!this.state.loading || image)) {

			this.setState({
				loading: true,
			})

			sendMessage({
				to: this.state.currentInteraction.customer_number,
				from: user.smsPhoneNumber,
				body: messageText,
				interactionId: this.state.currentInteraction.id,
				uid: user.uid,
				image: image,
			})
			.then(() => {
				if(document.getElementById('sendMessageText')) {
					document.getElementById('sendMessageText').value = ''
				}

				this.setState({
					loading: false,
					sendCustomReply: false,
				})
			})
		}
	}

	setStatus(status) {
		this.setState({loading: true})

		let statusStr = ''
		switch(status) {
			case 'new':
				statusStr = 'New';
				break;

			case 'in_progress':
				statusStr = 'In Progress';
				break;

			case 'complete':
				statusStr = 'Complete';
				break;

			default:
				statusStr = '';
				break;
		}

		return firestore
			.collection("interactions")
			.doc(this.state.currentInteraction.id)
			.update({
				status: status,
				updated: FieldValue.serverTimestamp(),
				lastMessage: "Status updated to " + statusStr,
			})
			.then(() => {
				return firestore	
					.collection("interactions")
					.doc(this.state.currentInteraction.id)
					.collection("events")
					.add({
						data: "Status updated to " + statusStr,
						type: "outgoing_message",
						uid: auth.currentUser.uid,
						created: FieldValue.serverTimestamp(),
					})
			})
			.then(() => {
				this.setState({loading: false})

				return
			})
	}

	getMacros() {
		firestore.collection("macros").where("uid", "==", auth.currentUser.uid).get().then((querySnapshot) => {
			let data = []

			querySnapshot.forEach((doc) => {
	            // doc.data() is never undefined for query doc snapshots
	            let datum = doc.data();
	            datum.id = doc.id;

	            data.push(datum)
	        });

        	this.setState({
        		macros: data,
        	})
		});
	}

	toggleComposeModal() {
		this.setState({
			showComposeModal: !this.state.showComposeModal,
		})
	}

	onChangeFile(event) {
	    event.stopPropagation();
	    event.preventDefault();
	    const storageRef = storage.ref()

	    const date = new Date().toJSON().split('T')[0]

	    let msgText = '';

	    if(document.getElementById('sendMessageText')) {
	    	msgText = document.getElementById('sendMessageText').value
	    }

	    if(document.getElementById('composeMessage')) {
	    	msgText = document.getElementById('composeMessage').value
	    }

	    Array.from(event.target.files).forEach((file) => {

	    	const random = Math.floor(Math.random() * 1000000000000);

		    const path = "messageImages/" + date + "/" + file.lastModified + "-" + random + "-" + encodeURIComponent(file.name)
		    const fileRef = storageRef.child(path);

		    fileRef.put(file, {
		    	contentType: file.type, 
		    	cacheControl: "public, max-age=86400",
		    }).then((snapshot) => {
		    	fileRef.getDownloadURL().then((url) => {
		    		this.sendMessage(msgText, url)
		    	});
			})
			.then(() => {
				if(this.state.showComposeModal) {
					this.toggleComposeModal()
				}
			})
	    })
	}

	render() {
		if(!this.state.loading && !this.state.user.smsPhoneNumber) {
			return (
				<Row style={{marginTop: 10}}>
					<Col>
						<div className="alert alert-info">
							Before messaging your customers, you'll need to set up a phone number to send/receive text messages. <Link to="/account/sms">Click here</Link> to choose a number.
						</div>
					</Col>
				</Row>
			);
		}

		if(!this.state.loading && this.state.user.smsPhoneNumber && this.state.interactions.length == 0 ) {
			return (
				<Row style={{marginTop: 10}}>
					<Col>
						<div className="alert alert-info">
							To get started, stay on this page and send a text message to {this.formatNumber(this.state.user.smsPhoneNumber)} from your phone.
						</div>
					</Col>
				</Row>
			);
		}

		return (
			<div id="messagesPage" className={(this.state.isMobile ? "mobile" : "desktop")}>
				{!this.state.isMobile &&
					<MessagesSidebar
						isMobile={this.state.isMobile}
						currentInteraction={this.state.currentInteraction}
						selectInteraction={this.selectInteraction}
						interactions={this.state.interactions}
						formatNumber={this.formatNumber}
						getInteractions={this.getInteractions}
						hasMore={this.state.moreInteractions}
						toggleComposeModal={this.toggleComposeModal} />
				}

				<div id="messagesBody">
					<div id="messagesBodyContent">

						{!this.state.loading && this.state.currentInteraction == null && this.state.isMobile &&
							<MessagesSidebar
								isMobile={this.state.isMobile}
								currentInteraction={this.state.currentInteraction}
								selectInteraction={this.selectInteraction}
								interactions={this.state.interactions}
								formatNumber={this.formatNumber}
								getInteractions={this.getInteractions}
								hasMore={this.state.moreInteractions}
								toggleComposeModal={this.toggleComposeModal} />
						}

						{this.state.loading && this.state.currentInteraction == null &&
							<div style={{margin: "0 25%"}}>
								<Loading />
							</div>
						}

						{(this.state.currentInteraction !== null ?
							<div>
								<div className="messagesBodyContentHeader">
									<h4>
										{this.state.isMobile && 
											<span className="pull-left">
												<a href="#" onClick={() => {
													this.selectInteraction(null)
												}}>
													<span className="fa-stack" style={{fontSize: "0.7em"}}>
													  <i className="fas fa-square fa-stack-2x" style={{verticalAlign: "middle"}}></i>
													  <i className="fas fa-arrow-left fa-stack-1x fa-inverse" style={{verticalAlign: "middle"}}></i>
													</span>

												</a>
												{" "}
											</span>
										}
										<span>{this.formatNumber(this.state.currentInteraction.customer_number)}</span>

										<span className="pull-right">
											{" "}
											<InteractionStatus data={this.state.currentInteraction} />
										</span>
									</h4>
								</div>
								<Row className="statusUpdateButtons">
									<Col>
										<Button variant="primary" size="sm" block disabled={this.state.limitReached || this.state.currentInteraction.status == "new" || this.state.loading} onClick={this.setStatus.bind(this, 'new')}>New</Button>
									</Col>

									<Col>
										<Button variant="secondary" size="sm" block disabled={this.state.limitReached || this.state.currentInteraction.status == "in_progress" || this.state.loading} onClick={this.setStatus.bind(this, 'in_progress')}>In Progress</Button>
									</Col>

									<Col>
										<Button variant="success" size="sm" block disabled={this.state.limitReached || this.state.currentInteraction.status == "complete" || this.state.loading} onClick={this.setStatus.bind(this, 'complete')}>Complete</Button>
									</Col>
								</Row>
								<div id="messagesLog">

									{this.state.interactionEvents.map((el) => {
										if(!el.data.length) {
											return null;
										}

										let data = el.data

										if(el.type == 'outgoing_message' || el.type == 'incoming_message') {
											data = data.replaceAll("\n", "<br>")

											return (
												<div className={"messageLogItem " + (el.type.indexOf('outgoing_message') > -1 ? 'me' : '')} key={el.id} dangerouslySetInnerHTML={{ __html: data }}></div>
											)

										} else if(el.type == 'outgoing_message_image' || el.type == 'incoming_message_image') {
										    return (
										    	<MessageImage 
										    		key={el.id}
										    		data={el}
										    		isMobile={this.state.isMobile} />
										    )
										}
									})}
								</div>

								<div className="sendMessageRow" style={{display: (!this.state.limitReached && this.state.sendCustomReply) ? "" : "none"}}>
									<textarea type="text" placeholder="What's your message?" rows="2" id="sendMessageText"></textarea>
									{this.state.loading &&
										<Loading />
									}
									{!this.state.loading &&
										<a onClick={this.sendMessage}>
											<i className="fa fa-arrow-circle-up sms-send-btn"></i>
										</a>
									}
								</div>

								{this.state.limitReached &&
									<div className="sendMessageRow">
										<div className="alert alert-danger">
											<i className="fas fa-radiation-alt"></i>
											{" "}Sorry, you have reached your limit on visits this month. To change your plan, <Link to="/account/billing">click here</Link>.
										</div>
									</div>
								}

								<div>
									<Dropdown as={ButtonGroup}>
										<Button disabled={this.state.limitReached || this.state.loading} variant="secondary" size="sm" style={{}} onClick={() => {
											document.getElementById('sendMessageText').value = ''
											this.setState({
												sendCustomReply: !this.state.sendCustomReply,
											})
										}}>
											<i className="fas fa-pencil-alt"></i>
											{" "}
											Custom
										</Button>
										<Dropdown.Toggle disabled={this.state.limitReached || this.state.loading} split variant="secondary" size="sm" id="dropdown-split-basic" />
										<Dropdown.Menu>
										    <Dropdown.Item href="#" onClick={(e) => {this.uploadFile.click(e)}}>
										    	<i className="fas fa-camera"></i>
										    	{" "}
										    	Send Image
										    </Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>

									{this.state.macros.map((el) => {
										return (
											
											<Button key={el.id} disabled={this.state.limitReached || this.state.loading} variant="primary" size="sm" style={{margin: 5}} onClick={() => {
												document.getElementById('sendMessageText').value = el.text
												this.setState({sendCustomReply: true})
											}}>
												{el.name}
											</Button>
										)
									})}									
								</div>
							</div>
						: null)}
					</div>
				</div>
				<ComposeModal
					show={this.state.showComposeModal}
					toggle={this.toggleComposeModal}
					selectInteraction={this.selectInteraction}
					limitReached={this.state.limitReached}
					usageRecords={this.state.usageRecords}
					getUsageRecords={this.getUsageRecords}
					macros={this.state.macros} 
					uploadFile={this.uploadFile}
				/>
				<input id="fileSelector"
				   type="file"
				   ref={(ref) => this.uploadFile = ref}
				   style={{display: 'none'}}
				   onChange={this.onChangeFile}
				   multiple
				/>
			</div>
		);
	}
}