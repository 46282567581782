import React, { useContext, useState } from "react";

import { UserContext } from "../providers/UserProvider";
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';

import PWAPrompt from 'react-ios-pwa-prompt'

const Home = (props) => {
	const user = useContext(UserContext).user || null;
	const [showVideo, setShowVideo] = useState(false)
	
	return (
		<div>
			{user !== null && 
				<div>
					<h1>Welcome back, {user.displayName || user.email}</h1>

					{user.smsPhoneNumber == null &&
						<div className="alert alert-info">
							<Link to="/account/sms">Click here</Link> to set up a SMS number and get started.
						</div>
					}

					{user.smsPhoneNumber != null &&
						<div className="alert alert-info">
							To get started, navigate on over to the <Link to="/messages">Messages</Link> page to communicate with your customers.
						</div>
					}

					<PWAPrompt promptOnVisit={0} timesToShow={10} delay={100} debug={false} copyClosePrompt={"Close"} />
				</div>
			}

			{user == null &&
				<div>
					<h1>Welcome!</h1>
					<p>Please <Link to="/login">Log In</Link> or <Link to="/signup">Sign Up</Link> to use Curbd.</p>
				</div>
			}

			{!showVideo &&
				<div>
					<Button variant="primary" onClick={() => {setShowVideo(true)}}>
						<i className="fas fa-video"></i> 
						{" "}
						Watch a 1 min. demo video
					</Button>
				</div>
			}

			{showVideo &&
				<div style={{overflowX: "hidden"}}>
					<iframe width="480" height="280" src="https://www.youtube-nocookie.com/embed/XpeDHjFOqdM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</div>
			}

		</div>
	)
}

export default Home;